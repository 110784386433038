import React from 'react'

import './Badge.scss'
import appStoreBadge from './app-store-badge.svg'
import googlePlayBadge from './google-play-badge.png'


const StoreBadge = ({size, url, image, message}) => (
  <a href={url}>
    <img className={`StoreBadge ${size}`} src={image} alt={message} title={message} />
  </a>
)

export const AppStoreBadge = ({ size }) => (
  <StoreBadge
    size={size}
    url='https://apps.apple.com/fi/app/redal/id1411553702?l=fi'
    image={appStoreBadge}
    message='Download on the App Store'
  />
)

export const PlayStoreBadge = ({ size }) => (
  <StoreBadge
    size={size}
    url='http://play.google.com/store/apps/details?id=fi.redal.mobile'
    image={googlePlayBadge}
    message='Get it on Google Play'
  />
)
