import Bowser from 'bowser'
import { v4 as uuidv4 } from 'uuid'

const browser = Bowser.parse(window.navigator.userAgent)
const name = `${browser.os.name} ${browser.os.versionName || browser.os.version || ''}`.trim()
const platform = browser.browser.name || 'unknown'
const platformVersion = browser.browser.version || 'unknown'

let deviceId = undefined

const buildDeviceInfo = (id) => ({ id, name, platform, platformVersion })

export const loadSession = () => {
  try {
    const value = localStorage.getItem('redal-session')
    return value ? JSON.parse(value) : undefined
  } catch (e) {
    return undefined
  }
}

export const storeSession = (session) => {
  return localStorage.setItem('redal-session', JSON.stringify(session))
}

export const clearSession = () => {
  return localStorage.removeItem('redal-session')
}

export const getDeviceInfo = () => {
  if (deviceId) {
    return buildDeviceInfo(deviceId)
  }
  else {
    deviceId = localStorage.getItem('redal-device-id')

    if (deviceId) {
      return buildDeviceInfo(deviceId)
    }
    else {
      deviceId = uuidv4()
      localStorage.setItem('redal-device-id', deviceId)
      return buildDeviceInfo(deviceId)
    }
  }
}
