import _ from 'lodash'

import config from './config'
import store from './store'
import { addProject, removeProject, setProjects, updateProject } from './reducer/projects'
import { addTaskComment, removeTaskComment, setTaskComments, updateTaskComment } from './reducer/taskComments'
import { addTask, removeTask, setTasks, updateTask } from './reducer/tasks'
import { addUser, setUsers, updateUser } from './reducer/users'

let ws = undefined
let connected = false
let heartbeatId = undefined
let activeCheckId = undefined
let reconnectCheckId = undefined
let lastActive = undefined

const send = (id, method) => {
  if (ws && connected) {
    console.log(`socket send ${method}`)
    try {
      ws.send(JSON.stringify({ id, method, params: {} }))
    }
    catch(e) {
      console.warn('socket send failed', e)
    }
  }
  else {
    console.log(`socket send failed ws=${ws} connected=${connected}`)
  }
}

const heartbeat = () => {
  send('heartbeat', 'ping')
}

const syncProjects = () => {
  send('sync-projects', 'project.list')
}

const syncTasks = () => {
  send('sync-tasks', 'task.list')
}

const syncTaskComments = () => {
  send('sync-task-comments', 'task.getComments')
}

const syncUsers = () => {
  send('sync-users', 'network.getUsers')
}

const handleMessage = (id, data) => {
  switch (id) {
    case 'heartbeat':
      lastActive = new Date()
      return

    case 'sync-projects':
      return store.dispatch(setProjects(data))
    case 'project.create':
      return store.dispatch(addProject(data))
    case 'project.update':
      return store.dispatch(updateProject(data))
    case 'project.delete':
      return store.dispatch(removeProject(data))

    case 'sync-tasks':
      return store.dispatch(setTasks(data))
    case 'task.create':
      return store.dispatch(addTask(data))
    case 'task.update':
    case 'task.activate':
    case 'task.complete':
    case 'task.approve':
      return store.dispatch(updateTask(data))
    case 'task.delete':
      return store.dispatch(removeTask(data))

    case 'sync-task-comments':
      return store.dispatch(setTaskComments(data))
    case 'task.addComment':
      return store.dispatch(addTaskComment(data))
    case 'task.updateComment':
      return store.dispatch(updateTaskComment(data))
    case 'task.deleteComment':
      return store.dispatch(removeTaskComment(data))

    case 'sync-users':
      return store.dispatch(setUsers(data))
    case 'network.updateUser':
      return store.dispatch(updateUser(data))
    case 'network.addUser':
      return store.dispatch(addUser(data))

    default:
  }
}

const activeCheck = () => {
  if (lastActive && ws) {
    const now   = new Date()
    const diff  = now - lastActive

    if (diff > 45000) {
      ws.close()
    }
  }
}

function tryConnect() {
  console.log('socket try connect')
  if (!connected) {
    start()
  }
}

const start = () => {
  console.log('socket start')

  const state = store.getState()
  const sessionId = _.get(state, 'application.session.id')

  if (_.isUndefined(sessionId)) {
    return
  }

  clearInterval(reconnectCheckId)

  ws = new WebSocket(`${config.wsUrl}/ws/${sessionId}?v=${config.version}&p=${config.platform}`)
  ws.onopen = () => {
    console.log('socket open')
    connected = true

    clearInterval(heartbeatId)
    heartbeatId = setInterval(heartbeat, 15000)

    clearInterval(activeCheckId)
    activeCheckId = setInterval(activeCheck, 60000)

    syncProjects()
    syncUsers()
    syncTasks()
    syncTaskComments()
  }
  ws.onmessage = (e) => {
    const message = JSON.parse(e.data)
    console.log('socket message', message.id, message.error || 'success')

    if (message.error) {
      console.warn('socket message error', message.id, message.error)
    }
    else {
      handleMessage(message.id, message.result)
    }
  }
  ws.onerror = (e) => {
    console.log('socket error', e.message)
  }
  ws.onclose = (e) => {
    console.log('socket close', e.code)
    connected = false
    clearInterval(heartbeatId)
    clearInterval(activeCheckId)
    reconnectCheckId = setInterval(tryConnect, 5000)
  }
}

const stop = () => {
  console.log('socket stop')
  connected = false

  if (ws) {
    ws.onclose = () => {}
    ws.close()
    ws = undefined
  }
}

export default {
  start,
  stop,
}
