import React from 'react'
import moment from 'moment'

import IconInfo from './IconInfo'
import Timestamp from './Timestamp'

const isExpired = (value) => {
  return moment(value) < moment()
}

const Deadline = ({ value }) => {
  return (
    <IconInfo icon='clock' variant={isExpired(value) ? 'danger' : undefined}>
      <Timestamp value={value} />
    </IconInfo>
  )
}

export default Deadline
