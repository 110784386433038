import React, { useCallback } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import _ from 'lodash'

import { setUnauthorized } from './reducer/application'
import './Header.scss'
import logo from './logo-white.svg'

const Header = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => _.get(state, 'application.session.user'))

  const handleLogout = useCallback(() => {
    dispatch(setUnauthorized())
  }, [dispatch])

  return (
    <header className='Header'>
      <Container>
        <Navbar bg='primary' variant='dark' expand='md'>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src={logo} className='logo' alt='Redal' />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='navbar-main' />
          {user && (
            <Navbar.Collapse id='navbar-main'>
              <Nav className='mr-auto'>
                <LinkContainer to='/projects'>
                  <Nav.Link>Projektit</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/network'>
                  <Nav.Link>Verkosto</Nav.Link>
                </LinkContainer>
              </Nav>
              <Nav>
                <LinkContainer to='/'>
                  <Nav.Link onSelect={handleLogout}>
                    Kirjaudu ulos
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar>
      </Container>
    </header>
  )
}

export default Header
