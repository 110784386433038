import { createSlice } from '@reduxjs/toolkit'
import { addMissing, updateOrAdd } from './_helpers'

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers(state, action) {
      return action.payload
    },
    clearUsers() {
      return []
    },
    addUser(state, action) {
      return addMissing(state, action.payload)
    },
    updateUser(state, action) {
      return updateOrAdd(state, action.payload)
    },
  }
})

export const { addUser, clearUsers, setUsers, updateUser } = usersSlice.actions

export default usersSlice.reducer
