import React from 'react'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'

import './Footer.scss'
import { AppStoreBadge, PlayStoreBadge } from './component/Badge'

const FooterLink = (props) => {
  const extraProps = props.href.indexOf('http') === -1 ? {} : {target: '_blank', rel: 'noopener noreferrer'}
  const className = props.className || ''

  return (
    <a
      href={props.href}
      className={`${className} btn btn-link text-primary text-decoration-none`}
      {...extraProps}
    >
      {props.children}
    </a>
  )
}

const Footer = (props) => {
  return (
    <footer className='Footer'>
      <Jumbotron fluid className='mb-0'>
        <Container>
          <Row className='justify-content-center text-center'>
            <Col>
              <h1>Redal Oy</h1>
            </Col>
          </Row>
          <Row className='justify-content-center text-center'>
            <Col sm={6} md={4} lg={3}>
              <FooterLink className='text-secondary' href='https://redal.fi'>redal.fi</FooterLink>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FooterLink className='text-secondary' href='mailto:asiakaspalvelu@redal.fi'>asiakaspalvelu@redal.fi</FooterLink>
            </Col>
          </Row>
          <Row className='justify-content-center text-center'>
            <Col sm={6} md={4} lg={3}>
              <FooterLink href='https://redal.fi/tietosuoja'>Tietosuoja</FooterLink>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FooterLink href='https://redal.fi/kayttoehdot'>Käyttöehdot</FooterLink>
            </Col>
          </Row>
          <Row className='justify-content-center text-center mt-4'>
            <Col sm={6} md={4} lg={3} className='mt-2 mb-2'>
              <AppStoreBadge size='md' />
            </Col>
            <Col sm={6} md={4} lg={3} className='mt-2 mb-2'>
              <PlayStoreBadge size='md' />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </footer>
  )
}

export default Footer
