import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NetworkSection from './section/network/NetworkSection'
import ProjectSection from './section/project/ProjectSection'
import socket from './socket'


const App = () => {
  useEffect(() => {
    socket.start()

    return function cleanup() {
      socket.stop()
    }
  }, [])

  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/projects'/>
      </Route>
      <Route path='/projects'>
        <ProjectSection />
      </Route>
      <Route path='/network'>
        <NetworkSection />
      </Route>
    </Switch>
  )
}

export default App
