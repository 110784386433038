import React from 'react'

const PropertyList = ({ properties }) => {
  return (
    <dl className='row'>
      {properties.map(([label, value], index) => (
        <React.Fragment key={`property-list-${index}`}>
          <dt className='col-12 col-sm-6 col-lg-4 text-sm-right'>{label}</dt>
          <dd className='col-12 col-sm-6 col-lg-8'>{value}</dd>
        </React.Fragment>
      ))}
    </dl>
  )
}

export default PropertyList
