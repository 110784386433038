import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars, faCheck, faCheckDouble, faChevronLeft, faClock, faCommentAlt, faFile,
  faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faPlay, faPlus
} from '@fortawesome/free-solid-svg-icons'

import './index.scss'
import Main from './Main'
import store from './store'

library.add(
  faBars, faCheck, faCheckDouble, faChevronLeft, faClock, faCommentAlt, faFile,
  faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faPlay, faPlus
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
