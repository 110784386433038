import axios from 'axios'

import config from './config'
import { getDeviceInfo } from './storage'

const device = getDeviceInfo()

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common = {}
axios.defaults.headers.common['X-Version'] = config.version
axios.defaults.headers.common['X-Platform'] = config.platform

const client = axios.create({
  baseURL: config.apiUrl
})
let requestId = 0

export const errors = {
  // JSON RPC specific
  METHOD_NOT_FOUND: -32601,
  INVALID_PARAMS: -32602,
  SERVER: -32603,

  // Service errors
  NETWORK: -32000,
  UNIQUE: -32001,
  LOGIN_FAILED: -32002,
  UNAUTHORIZED: -32003,
  NOT_ACCEPTABLE: -32004,
  RETRY_LATER: -32005,
}

const buildFailure = (code, message) => {
  return { success: false, error: { code, message } }
}

const buildSuccess = (data) => {
  return { success: true, data }
}

export const setSessionId = (sessionId) => {
  client.defaults.headers.common['X-Session'] = sessionId
}

export const unsetSessionId = () => {
  delete client.defaults.headers.common['X-Session']
}

export const execute = (method, params, token) => {
  const config = token ? { headers: { 'X-Session': token } } : {}
  return client
    .post('/api', { id: (++requestId).toString(), method, params }, config)
    .then(response => {
      if (response.data.error) {
        return { success: false, error: response.data.error }
      }
      else {
        return buildSuccess(response.data.result)
      }
    })
    .catch(e => {
      console.log('execute error', e.toJSON())
      return buildFailure(errors.NETWORK, 'Execution failed')
    })
}

export const sessionRefresh = async (session) => {
  const params = {
    id: session.user.id,
    device
  }
  return execute('session.refresh', params, session.id)
}

export const loginStart = async () => {
  return await execute('login.transfer.start', { device })
}

export const loginComplete = async (id) => {
  return await execute('login.transfer.complete', { id })
}

export const setInviteKey = async () => {
  return await execute('user.setInviteKey', {})
}

export const getDownloadUrl = async (id) => {
  return await execute('media.createDownloadUrl', { id })
}
