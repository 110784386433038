import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BrowserRouter as Router } from 'react-router-dom'

import { LOADING, READY, UNAUTHORIZED, initialize } from './reducer/application'
import Login from './component/Login'
import Header from './Header'
import Footer from './Footer'
import App from './App'
import './Main.scss'


const Main = () => {
  const dispatch = useDispatch()
  const application = useSelector(state => state.application)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(initialize())
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Router>
      <div className='Main'>
        <Header />
        <div className='Main-content mt-4 mb-4'>
          {application.status === LOADING && (
            <Container>
              <Row className='align-items-center'>
                <Col className='text-center m-5'>
                  <Spinner animation='border' size='lg' variant='primary' />
                </Col>
              </Row>
            </Container>
          )}
          {application.status === UNAUTHORIZED && (
            <Login />
          )}
          {application.status === READY && (
            <App />
          )}
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default Main
