import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const colors = {
  'primary': '#c7342e',
  'danger': '#dc3545',
  'gray-500': '#adb5bd',
  'gray-600': '#6c757d',
  'gray': '#6c757d',
}

const Icon = ({ name, variant, ...rest }) => {
  return (
    <FontAwesomeIcon icon={name} color={colors[variant]} {...rest} />
  )
}

export default Icon
