import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import QRCode from 'react-qr-code'

import { setReady } from '../reducer/application'
import { loginComplete, loginStart } from '../client'
import Icon from './Icon'
import './Login.scss'

const timeouts = [
  3000, 2500, 2500, 2500, 2500, 2000, 2000, 2000, 2000, 2000,
  1500, 1500, 1500, 1500, 1500, 1000, 1000, 1000, 1000, 1000,
]

let pollId = undefined
let pollTimeouts = []

const startPolling = (id, onSuccess, onExpired) => {
  pollTimeouts = [...timeouts]

  const poll = async () => {
    const { success, data } = await loginComplete(id)
    if (success) {
      onSuccess(data)
    }
    else {
      const nextTimeout = pollTimeouts.shift()
      if (nextTimeout) {
        pollId = setTimeout(poll, nextTimeout)
      }
      else {
        onExpired()
      }
    }
  }

  pollId = setTimeout(poll, pollTimeouts.shift())
}

const stopPolling = () => {
  clearTimeout(pollId)
}

const Login = () => {
  const dispatch            = useDispatch()
  const [phase, setPhase]   = useState('initialize')
  const [flowId, setFlowId] = useState(undefined)

  useEffect(() => {
    async function startFlow() {
      const result = await loginStart()
      if (result.success) {
        setFlowId(result.data)
        setPhase('authorize')
      }
      else {
        setPhase('error')
      }
    }
    startFlow()
  }, [])

  useEffect(() => {
    if (phase === 'authorize' && flowId) {
      startPolling(flowId, data => dispatch(setReady(data)), () => setPhase('expired'))

      return function cleanup() {
        stopPolling()
      }
    }
  }, [phase, flowId, dispatch])

  const restartAuthorize = () => {
    setPhase('authorize')
  }

  return (
    <Container>
      {phase === 'error' && (
        <Row>
          <Col>Error</Col>
        </Row>
      )}
      {phase === 'initialize' && (
        <Row className='align-items-center'>
          <Col className='text-center m-5'>
            <Spinner animation='border' size='lg' variant='primary' />
          </Col>
        </Row>
      )}
      {(phase === 'authorize' || phase === 'expired') && (
        <Row className='align-items-center'>
          <Col sm={6} md={7} lg={{ span: 5, offset: 2 }}>
            <Row>
              <Col>
                <h3 className='mb-4'>Ota Redal Web käyttöön:</h3>
                <ol>
                  <li className='font-md mb-3'>Avaa Redal-sovellus puhelimellasi</li>
                  <li className='font-md mb-3'>Avaa <Icon className='ml-1 mr-1' name='bars' variant='primary' /> valikosta <em>Sisäänkirjautumiset</em></li>
                  <li className='font-md mb-3'>Valitse <Icon name='plus' variant='primary' /></li>
                  <li className='font-md mb-3'>Kohdista viereinen koodi puhelimen näytölle</li>
                </ol>
              </Col>
            </Row>
          </Col>
          {flowId && (
            <Col sm={6} md={5} lg={3} className='text-center'>
              <div className={`QRCode-${phase}`}>
                <QRCode size={256} value={flowId} />
              </div>
              {phase === 'expired' && (
                <div className='Retry'>
                  <Button onClick={restartAuthorize}>Yritä uudelleen</Button>
                </div>
              )}
            </Col>
          )}
        </Row>
      )}
    </Container>
  )
}

export default Login
