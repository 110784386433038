import _ from 'lodash'

export function addMissing(items, item) {
  if (_.isUndefined(_.find(items, ['id', item.id]))) {
    return items.concat(item)
  }
  else {
    return items
  }
}

export function removeById(items, id) {
  return _.reject(items, ['id', id])
}

export function updateOrAdd(items, item) {
  return _.reject(items, ['id', item.id]).concat(item)
}
