import { createSlice } from '@reduxjs/toolkit'
import { addMissing, removeById, updateOrAdd } from './_helpers'

const taskCommentsSlice = createSlice({
  name: 'taskComments',
  initialState: [],
  reducers: {
    setTaskComments(state, action) {
      return action.payload
    },
    clearTaskComments() {
      return []
    },
    addTaskComment(state, action) {
      return addMissing(state, action.payload)
    },
    updateTaskComment(state, action) {
      return updateOrAdd(state, action.payload)
    },
    removeTaskComment(state, action) {
      return removeById(state, action.payload)
    },
  }
})

export const { addTaskComment, clearTaskComments, removeTaskComment, setTaskComments, updateTaskComment } = taskCommentsSlice.actions

export default taskCommentsSlice.reducer
