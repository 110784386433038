import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import EmptyListMessage from '../../component/EmptyListMessage'
import Icon from '../../component/Icon'

import MediaList from '../../component/MediaList'
import PropertyList from '../../component/PropertyList'
import RichText from '../../component/RichText'
import Timestamp from '../../component/Timestamp'


const TaskView = () => {
  const { taskId } = useParams()
  const history = useHistory()

  const tasks = useSelector(state => state.tasks)
  const comments = useSelector(state => state.taskComments)

  const [task, setTask] = useState(undefined)
  const [taskComments, setTaskComments] = useState(undefined)
  const [taskProperties, setTaskProperties] = useState([])

  const handleBack = useCallback(() => history.goBack(), [history])

  useEffect(() => {
    setTask(_.find(tasks, ['id', taskId]))
  }, [taskId, tasks])

  useEffect(() => {
    if (task) {
      const properties = [
        ['Määräaika', <Timestamp value={task.deadline} />],
        ['Tekijä', _.get(task, 'user.name', <span className='text-muted'>Ei valittu</span>)]
      ]
      setTaskProperties(properties)
    }
    else {
      setTaskProperties([])
    }
  }, [task])

  useEffect(() => {
    if (task) {
      const visible = _.filter(comments, ['taskId', task.id])
      setTaskComments(_.orderBy(visible, ['createdAt'], ['desc']))
    }
    else {
      setTaskComments(undefined)
    }
  }, [comments, task])

  return task ? (
    <>
    <Card border='light'>
      <Card.Header className='lead'>
        <Button className='card-header-button' variant='link' onClick={handleBack}><Icon name='chevron-left' /></Button>
        {task.name}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} sm={6}>
            {task.description !== '' && (
              <Card.Text as='span'>
                <RichText>{task.description}</RichText>
              </Card.Text>
            )}
          </Col>
          <Col xs={12} sm={6}>
            <PropertyList properties={taskProperties} />
          </Col>
        </Row>
        {task.medias.length !== 0 && (
          <MediaList medias={task.medias} />
        )}
      </Card.Body>
    </Card>
    {taskComments && (
      <Card border='light'>
        <Card.Header className='lead'>Kommentit</Card.Header>
        {taskComments.length === 0 ? (
          <Card.Body>
            <EmptyListMessage icon='comment-alt' message='Ei kommentteja' />
          </Card.Body>
        ) : (
          <ListGroup variant='flush'>
            {taskComments.map(comment => (
              <ListGroup.Item key={comment.id}>
                <div className='d-flex w-100 justify-content-between mb-3 text-muted'>
                  <span>{comment.user.name}</span>
                  <span>
                <Timestamp value={comment.createdAt} />
              </span>
                </div>
                {comment.content !== '' && (
                  <RichText>{comment.content}</RichText>
                )}
                {comment.medias.length !== 0 && (
                  <MediaList medias={comment.medias} />
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card>
    )}
    </>
  ) : (
    <div />
  )
}

export default TaskView
