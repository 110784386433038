import React from 'react'
import moment from 'moment'
import Moment from 'react-moment'
import _ from 'lodash'

const currentYear = moment().year()
const longFormat = 'DD.MM.YYYY HH:mm'
const shortFormat = 'DD.MM. HH:mm'

const Timestamp = ({ value, className }) => {
  return (
    <Moment className={className} format={_.startsWith(value, currentYear) ? shortFormat : longFormat} local>{value}</Moment>
  )
}

export default Timestamp
