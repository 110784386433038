import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Nav, Row, Table } from 'react-bootstrap'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import _ from 'lodash'

import Deadline from '../../component/Deadline'
import EmptyListMessage from '../../component/EmptyListMessage'
import IconInfo from '../../component/IconInfo'
import PropertyList from '../../component/PropertyList'
import RichText from '../../component/RichText'
import Timestamp from '../../component/Timestamp'
import { setProjectsActiveTab } from '../../reducer/application'


const timeField = {
  active: 'deadline',
  completed: 'completed.time',
  approved: 'approved.time',
}

const sortDirections = {
  active: ['asc', 'asc'],
  completed: ['desc', 'asc'],
  approved: ['desc', 'asc'],
}

const ProjectView = () => {
  const { projectId } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const projects = useSelector(state => state.projects)
  const tasks = useSelector(state => state.tasks)
  const activeTab = useSelector(state => state.application.projects.activeTab)

  const [project, setProject] = useState(undefined)
  const [visibleTasks, setVisibleTasks] = useState(undefined)
  const [projectProperties, setProjectProperties] = useState([])

  const setActiveTab = (tab) => dispatch(setProjectsActiveTab(tab))
  const openTask = (task) => history.push(`${url}/task/${task.id}`)

  useEffect(() => {
    setProject(_.find(projects, ['id', projectId]))
  }, [projectId, projects])

  useEffect(() => {
    if (project) {
      const properties = [
        ['Omistaja', project.owner.name]
      ]

      if (_.has(project, 'customer.name')) {
        properties.push(['Asiakas', project.customer.name])
      }

      setProjectProperties(properties)
    }
    else {
      setProjectProperties([])
    }
  }, [project])

  useEffect(() => {
    if (project) {
      const sortField = timeField[activeTab]
      const visible = _.filter(tasks, { projectId: project.id, status: activeTab })
      setVisibleTasks(_.orderBy(visible, [sortField, 'deadline'], sortDirections[activeTab]))
    }
    else {
      setVisibleTasks(undefined)
    }
  }, [project, tasks, activeTab])

  return project ? (
    <>
      <Card border='light'>
        <Card.Header className='lead'>{project.name}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} sm={6}>
              {project.description !== '' && (
                <Card.Text as='span'>
                  <RichText>{project.description}</RichText>
                </Card.Text>
              )}
            </Col>
            <Col xs={12} sm={6}>
              <PropertyList properties={projectProperties} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {visibleTasks && (
        <Card border='light'>
          <Card.Header>
            <h5>Tehtävät</h5>
            <Nav justify variant='tabs' onSelect={setActiveTab} activeKey={activeTab}>
              <Nav.Item>
                <Nav.Link eventKey='active'>Avoimet</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='completed'>Tehdyt</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='approved'>Kuitatut</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          {visibleTasks.length === 0 ? (
            <EmptyListMessage icon='check' message='Ei tehtäviä' />
          ) : (
            <Table responsive striped hover className='selectable'>
              <tbody>
              {visibleTasks.map((task, index) => (
                <TaskTableRow
                  key={task.id}
                  task={task}
                  index={index}
                  activeTab={activeTab}
                  onClick={openTask}
                />
              ))}
              </tbody>
            </Table>
          )}
        </Card>
      )}
    </>
  ) : (
    <div />
  )
}

const TaskTableRow = ({ task, onClick, activeTab, index }) => {
  const handleClick = useCallback(() => onClick(task), [task, onClick])

  return (
    <tr key={task.id} className={index === 0 ? 'first' : ''} onClick={handleClick}>
      <td>{task.name}</td>
      <td className='text-right'>
        {activeTab === 'active' && (
          <Deadline value={task.deadline} />
        )}
        {activeTab === 'completed' && (
          <IconInfo icon='check'>
            <Timestamp value={_.get(task, timeField['completed'])} />
          </IconInfo>
        )}
        {activeTab === 'approved' && (
          <IconInfo icon='check-double'>
            <Timestamp value={_.get(task, timeField['approved'])} />
          </IconInfo>
        )}
      </td>
    </tr>
  )
}

export default ProjectView
