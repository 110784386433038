import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Container, ListGroup, Row, Spinner } from 'react-bootstrap'
import _ from 'lodash'

import { updateInviteKey } from '../../reducer/application'


const NetworkSection = () => {
  const dispatch  = useDispatch()
  const users     = useSelector(state => state.users)
  const inviteKey = useSelector(state => _.get(state, 'application.session.user.inviteKey'))

  useEffect(() => {
    if (_.isUndefined(inviteKey)) {
      dispatch(updateInviteKey())
    }
  }, [inviteKey, dispatch])

  return (
    <Container>
      <Row>
        <Col md={12} lg={6}>
          <Container>
            <Row>
              <Col>
                <Card border='light'>
                  <Card.Header className='lead'>
                    Kutsulinkki
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      Jaa kutsulinkki henkilölle jonka toivot näkyvän kontaktilistassasi
                    </Card.Text>
                    {!inviteKey && (
                      <Card.Text className='text-center'>
                        <Spinner animation='border' variant='primary' />
                      </Card.Text>
                    )}
                    {inviteKey && (
                      <Card.Text className='text-monospace'>https://redal.app/s/invite/{inviteKey}</Card.Text>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={12} lg={6}>
          <Container>
            <Row>
              <Col>
                <Card border='light'>
                  <Card.Header className='lead'>
                    Käyttäjät
                  </Card.Header>
                  <ListGroup variant='flush'>
                    {users.map(user => (
                      <ListGroup.Item key={user.id}>
                        <div className='mb-2'>{user.name}</div>
                        <div><a href={`mailto:${user.email}`}>{user.email}</a></div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default NetworkSection
