import React from 'react'
import Linkify from 'react-linkify'

const RichText = ({ children }) => {
  return (
    <Linkify>
      <p className='rich-text'>
        {children}
      </p>
    </Linkify>
  )
}

export default RichText
