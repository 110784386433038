import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import _ from 'lodash'

import { ReactComponent as Logo } from '../../logo-white.svg'
import ProjectView from './ProjectView'
import TaskView from './TaskView'

const LogoView = () => {
  return <Logo className='logo' />
}

const ProjectSection = () => {
  const { path, url } = useRouteMatch()
  const projects = useSelector(state => _.sortBy(state.projects, 'name'))

  return (
    <Container>
      <Row>
        <Col sm={12} md={5} lg={4} xl={3}>
          <Card border='light'>
            <Card.Header className='lead'>Projektit</Card.Header>
            <ListGroup variant='flush'>
              {projects.map(project => (
                <LinkContainer key={project.id} to={`${url}/${project.id}`}>
                  <ListGroup.Item action>
                    {project.name}
                  </ListGroup.Item>
                </LinkContainer>
              ))}
            </ListGroup>
          </Card>
        </Col>
        <Col className='mt-4 mt-md-0' sm={12} md={7} lg={8} xl={9}>
          <Switch>
            <Route exact path={path} component={LogoView} />
            <Route path={`${path}/:projectId/task/:taskId`} component={TaskView} />
            <Route path={`${path}/:projectId`} component={ProjectView} />
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

export default ProjectSection
