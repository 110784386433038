import { createSlice } from '@reduxjs/toolkit'
import { addMissing, removeById, updateOrAdd } from './_helpers'

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: [],
  reducers: {
    setTasks(state, action) {
      return action.payload
    },
    clearTasks() {
      return []
    },
    addTask(state, action) {
      return addMissing(state, action.payload)
    },
    updateTask(state, action) {
      return updateOrAdd(state, action.payload)
    },
    removeTask(state, action) {
      return removeById(state, action.payload)
    },
  }
})

export const { addTask, clearTasks, removeTask, setTasks, updateTask } = tasksSlice.actions

export default tasksSlice.reducer
