import { createSlice } from '@reduxjs/toolkit'
import { addMissing, removeById, updateOrAdd } from './_helpers'

const projectsSlice = createSlice({
  name: 'projects',
  initialState: [],
  reducers: {
    setProjects(state, action) {
      return action.payload
    },
    clearProjects() {
      return []
    },
    addProject(state, action) {
      return addMissing(state, action.payload)
    },
    updateProject(state, action) {
      return updateOrAdd(state, action.payload)
    },
    removeProject(state, action) {
      return removeById(state, action.payload)
    },
  }
})

export const { addProject, clearProjects, removeProject, setProjects, updateProject } = projectsSlice.actions

export default projectsSlice.reducer
