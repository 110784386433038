import { combineReducers } from 'redux'

import application from './application'
import projects from './projects'
import taskComments from './taskComments'
import tasks from './tasks'
import users from './users'

export default combineReducers({
  application,
  projects,
  tasks,
  taskComments,
  users,
})
