import React, { useCallback, useState } from 'react'
import { Button, Col, Figure, Modal, Row, Spinner } from 'react-bootstrap'
import { Player, LoadingSpinner } from 'video-react'
import AudioPlayer from 'react-h5-audio-player'
import { useSelector } from 'react-redux'
import Img from 'react-image'
import * as mime from 'mime-types'

import { getDownloadUrl } from '../client'
import Icon from './Icon'


const icons = {
  xlsx: 'file-excel',
  xls: 'file-excel',
  doc: 'file-word',
  docx: 'file-word',
  ppt: 'file-powerpoint',
  pptx: 'file-powerpoint',
  pdf: 'file-pdf',
}

const fileExtension = ({ contentType }) => {
  return mime.extension(contentType) || 'bin'
}

const fileIcon = (file) => {
  const extension = fileExtension(file)
  return icons[extension] || 'file'
}

const downloadMedia = async (media) => {
  const { success, data } = await getDownloadUrl(media.id)

  if (success) {
    const extension = fileExtension(media)
    const fileName = `redal-${media.id}.${extension}`
    const url = data + '&response-content-disposition=' + encodeURIComponent(`attachment; filename="${fileName}"`)
    window.open(url)
  }
}

const MediaList = ({ medias }) => {
  const mediaToken = useSelector(state => state.application.session.mediaToken)

  const [activeImage, setActiveImage] = useState(undefined)
  const closeImageModal = useCallback(() => setActiveImage(undefined), [setActiveImage])
  const openActiveImage = useCallback(async () => await downloadMedia(activeImage), [activeImage])

  const [activeVideo, setActiveVideo] = useState(undefined)
  const closeVideoModal = useCallback(() => setActiveVideo(undefined), [setActiveVideo])
  const openActiveVideo = useCallback(async () => await downloadMedia(activeVideo), [activeVideo])

  const [activeAudio, setActiveAudio] = useState(undefined)
  const closeAudioModal = useCallback(() => setActiveAudio(undefined), [setActiveAudio])
  const openActiveAudio = useCallback(async () => await downloadMedia(activeAudio), [activeAudio])

  const [activeDocument, setActiveDocument] = useState(undefined)
  const closeDocumentModal = useCallback(() => setActiveDocument(undefined), [setActiveDocument])
  const openActiveDocument = useCallback(async () => await downloadMedia(activeDocument), [activeDocument])

  return (
      <Row>
        {medias.map(media => (
          <Col key={media.id} xs={6} md={4} lg={3} xl={2}>
            <Figure className='w-100'>
              <MediaView
                media={media}
                mediaToken={mediaToken}
                onImageClick={setActiveImage}
                onAudioClick={setActiveAudio}
                onVideoClick={setActiveVideo}
                onDocumentClick={setActiveDocument}
              />
              {media.description && (
                <Figure.Caption>
                  {media.description}
                </Figure.Caption>
              )}
            </Figure>
          </Col>
        ))}
        {activeImage && (
          <Modal show onHide={closeImageModal}>
            <Modal.Header closeButton>
              <Modal.Title>{activeImage.description || 'Kuva'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
              <Img
                className='img-fluid'
                src={`${activeImage.url}?t=${mediaToken}`}
                alt={activeImage.description}
                loader={<Spinner animation='border' size='lg' variant='primary' />}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='link' onClick={closeImageModal}>Sulje</Button>
              <Button variant='primary' onClick={openActiveImage}>Lataa</Button>
            </Modal.Footer>
          </Modal>
        )}
        {activeAudio && (
          <Modal show onHide={closeAudioModal}>
            <Modal.Header closeButton>
              <Modal.Title>{activeAudio.description || 'Audio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AudioPlayer
                autoPlay
                src={`${activeAudio.url}?t=${mediaToken}`}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='link' onClick={closeAudioModal}>Sulje</Button>
              <Button variant='primary' onClick={openActiveAudio}>Lataa</Button>
            </Modal.Footer>
          </Modal>
        )}
        {activeVideo && (
          <Modal show size='lg' className='h-75' onHide={closeVideoModal}>
            <Modal.Header closeButton>
              <Modal.Title>{activeVideo.description || 'Video'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='w-100'>
              <Player autoPlay fluid aspectRatio='16:9' src={`${activeVideo.url}?t=${mediaToken}`}>
                <LoadingSpinner />
              </Player>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='link' onClick={closeVideoModal}>Sulje</Button>
              <Button variant='primary' onClick={openActiveVideo}>Lataa</Button>
            </Modal.Footer>
          </Modal>
        )}
        {activeDocument && (
          <Modal show onHide={closeDocumentModal}>
            <Modal.Header closeButton>
              <Modal.Title>{activeDocument.description || 'Tiedosto'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='text-center d-flex flex-column justify-content-center align-items-center'>
                <Icon name={fileIcon(activeDocument)} variant='primary' size='4x' />
                <span className='mt-2 text-primary'>{fileExtension(activeDocument)}</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='link' onClick={closeDocumentModal}>Sulje</Button>
              <Button variant='primary' onClick={openActiveDocument}>Lataa</Button>
            </Modal.Footer>
          </Modal>
        )}
      </Row>
  )
}

const MediaView = (props) => {
  switch (props.media.category) {
    case 'image':
      return <ImageView {...props} />

    case 'audio':
      return <PlayerView {...props} onPlay={props.onAudioClick} />

    case 'video':
      return <PlayerView {...props} onPlay={props.onVideoClick} />

    default:
      return <DocumentView {...props} />
  }
}

const ImageView = ({ media, onImageClick, mediaToken }) => {
  const handleClick = useCallback(() => onImageClick(media), [media, onImageClick])

  return (
    <Img
      className='figure-img img-fluid border selectable'
      src={`${media.thumbnailUrl}?t=${mediaToken}`}
      alt={media.description}
      onClick={handleClick}
      loader={<Spinner animation='border' size='lg' variant='primary' />}
    />
  )
}

const PlayerView = ({ media, onPlay }) => {
  const handleClick = useCallback(() => onPlay(media), [media, onPlay])

  return (
    <div className='aspect-ratio-1 border figure-img selectable bg-light' onClick={handleClick}>
      <div className='aspect-ratio-content text-center d-flex flex-column justify-content-center align-items-center'>
        <Icon name='play' variant='primary' size='2x' />
      </div>
    </div>
  )
}

const DocumentView = ({ media, onDocumentClick }) => {
  const handleClick = useCallback(() => onDocumentClick(media), [media, onDocumentClick])

  return (
    <div className='aspect-ratio-1 border figure-img selectable' onClick={handleClick}>
      <div className='aspect-ratio-content text-center d-flex flex-column justify-content-center align-items-center'>
        <Icon name={fileIcon(media)} variant='primary' size='2x' />
        <span className='mt-2 text-primary'>{fileExtension(media)}</span>
      </div>
    </div>
  )
}

export default MediaList
