import { createSlice } from '@reduxjs/toolkit'

import { sessionRefresh, setSessionId, unsetSessionId, setInviteKey } from '../client'
import { clearSession, loadSession, storeSession } from '../storage'

export const LOADING = 'LOADING'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const READY = 'READY'

const initialState = {
  status: LOADING,
  session: undefined,
  projects: {
    activeTab: 'active'
  }
}

const applicationSlice = createSlice({
  name: 'application',
  initialState: initialState,
  reducers: {
    unsetSession(state) {
      state.status = UNAUTHORIZED
      state.session = undefined
    },
    setSession(state, action) {
      state.status = READY
      state.session = action.payload
    },
    setUser(state, action) {
      state.session.user = action.payload
    },
    setProjectsActiveTab(state, action) {
      state.projects.activeTab = action.payload
    },
  }
})

export const { setProjectsActiveTab, unsetSession, setSession, setUser } = applicationSlice.actions

export const initialize = () => async dispatch => {
  const storedSession = loadSession()
  if (storedSession) {
    const { success, data } = await sessionRefresh(storedSession)
    if (success) {
      dispatch(setReady(data))
    }
    else {
      dispatch(setUnauthorized())
    }
  }
  else {
    dispatch(setUnauthorized())
  }
}

export const setReady = (session) => async dispatch => {
  setSessionId(session.id)
  storeSession(session)
  dispatch(setSession(session))
}

export const setUnauthorized = () => async dispatch => {
  unsetSessionId()
  clearSession()
  dispatch(unsetSession())
}

export const updateInviteKey = () => async dispatch => {
  const { success, data } = await setInviteKey()
  if (success) {
    dispatch(setUser(data))
  }
}

export default applicationSlice.reducer
