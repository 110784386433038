import React from 'react'

import Icon from './Icon'

const IconInfo = ({ icon, variant, children }) => {
  return (
    <div>
      <Icon name={icon} variant={variant} />
      {' '}
      {React.Children.map(children, (child) => (
        React.cloneElement(child, {
          className: variant ? `text-${variant} ${child.props.className}` : child.props.className
        })
      ))}
    </div>
  )
}

export default IconInfo
