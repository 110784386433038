import React from 'react'
import { Figure } from 'react-bootstrap'

import Icon from './Icon'

const EmptyListMessage = ({ icon, message }) => {
  return (
    <Figure className='w-100 p-5 text-center'>
      <Icon name={icon} variant='gray-500' className='figure-img img-fluid' size='3x' />
      <Figure.Caption>{message}</Figure.Caption>
    </Figure>
  )
}

export default EmptyListMessage
